import React, { useState, useContext } from 'react';
import axios from 'axios';
import userContext from "../context/UserContext"; // Make sure this context exists

export default function User() {
    const { token } = useContext(userContext);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleChangePassword = async (e) => {
    e.preventDefault();
    
    // Check if newPassword and confirmPassword match
    if (newPassword !== confirmPassword) {
      setMessage("New password and confirm password do not match.");
      return;
    }
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/change-password`, {
        currentPassword,
        newPassword
      }, {
        headers: {
          Authorization: `Bearer ${token}` // Use the token from context
        }
      });

      setMessage(response.data.message);
      // Optionally reset fields after success
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Error changing password:', error);
      setMessage(error.response ? error.response.data.message : 'Error changing password');
    }
  };

  return (
    <div className="container col-md-4 mh-100 my-5" style={{ boxShadow: "2px 2px 2px 2px" }}>
      <h1 className="my-4 text-center">Change Password</h1>
      {message && <div className="alert alert-info">{message}</div>}
      <form onSubmit={handleChangePassword}>
        <div className="form-group">
          <label>Current Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Current Password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>New Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Confirm New Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Change Password
        </button>
      </form>
      <br /><br />
    </div>
  );
}
