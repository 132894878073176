import React, { useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import userContext from "./context/UserContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CSS/Login.css";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const { setUser, setToken } = useContext(userContext);

    const handleLogin = async (e) => {
        e.preventDefault();
        const credentials = { username, password };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/login`,
                credentials,
                {
                    headers: { "Content-Type": "application/json" },
                }
            );

            // Set user and token in context
            setUser({ username });
            setToken(response.data.token);
            //console.log("Token received:", response.data.token);
            navigate("/dashboard"); // Navigate to Dashboard
        } catch (err) {
            setError(
                err.response
                    ? err.response.data.message
                    : "Login failed. Please try again."
            );
        }
    };

    const handleBack = () => {
        navigate('/'); // Navigate back to the home page
    };

    return (
      <div className="login-background">
          <div className="container col-md-4 mh-100 my-5" style={{ boxShadow: "2px 2px 2px 2px", backgroundColor: 'white' }}>
              <h1 className="my-4 text-center">Login</h1>
              <form onSubmit={handleLogin}>
                  <div className="form-group">
                      <label htmlFor="usernameInput">Username</label>
                      <input
                          type="text"
                          className="form-control"
                          id="usernameInput"
                          placeholder="Username"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          required
                          autoComplete="username"
                      />
                  </div>
                  <div className="form-group">
                      <label htmlFor="passwordInput">Password</label>
                      <input
                          type="password"
                          className="form-control"
                          id="passwordInput"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                          autoComplete="current-password"
                      />
                  </div>
                  <br />
                  {error && <p className="text-danger">{error}</p>}
                  <button type="submit" className="btn btn-primary">
                      Login
                  </button>
                  <button type="button" className="btn btn-secondary ml-2" onClick={handleBack}>
                      Back
                  </button>
              </form>
              <br /><br />
          </div>
      </div>
  );
};

export default Login;
