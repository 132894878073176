import { Document, Page, StyleSheet, View, Text } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    flexDirection: 'row',
    wrap: false,
  },
  view: {
    flex: 1,
    margin: 10,
    padding: 10,
    border: '1px solid #000',
    borderRadius: 5,
  },
  title: {
    fontSize: 14,
    marginBottom: 10,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  header: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  text: {
    fontSize: 10,
  },
  table: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    padding: 5,
    marginBottom: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCell: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    padding: 5,
    flex: 1,
  },
});

const Table = ({ children }) => {
  return <View style={styles.table}>{children}</View>;
};

const TableRow = ({ children }) => {
  return <View style={styles.tableRow}>{children}</View>;
};

const TableCell = ({ children }) => {
  return <View style={styles.tableCell}><Text style={styles.text}>{children}</Text></View>;
};

const FeePdf = ({ data }) => {
  if (!data) return "No data is passed to FeePdf.js";

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        {/* Student Copy */}
        <View style={styles.view}>
        <Text style={styles.header}>Your School Name</Text>
        <Text style={styles.text}>Address, contact no of your school</Text>
          
          <Text style={styles.text}>Student Copy</Text>
          <Table>
            <TableRow>
              <TableCell>Id: {data.idf}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Adm No:{data.fee_adm_no}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Name:{data.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Standard:{data.FeeStandard}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Monthly Fee</TableCell>
              <TableCell>{data.monthly_fee}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Fine</TableCell>
              <TableCell>{data.fine_fee}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Arrears</TableCell>
              <TableCell>{data.arrears}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Balance</TableCell>
              <TableCell>{data.balance}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total Fee</TableCell>
              <TableCell>{data.total_fee}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Collection</TableCell>
              <TableCell>{data.collection}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Month/Year</TableCell>
              <TableCell>{data.fmonth} / {data.fyear}</TableCell>
            </TableRow>
          </Table>
        </View>

        <View style={styles.view}>
        <Text style={styles.header}>Your School Name</Text>
        <Text style={styles.text}>Address, contact no of your school</Text>
          
          <Text style={styles.text}>Student Copy</Text>
          <Table>
            <TableRow>
              <TableCell>Id: {data.idf}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Adm No:{data.fee_adm_no}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Name:{data.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Standard:{data.FeeStandard}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Monthly Fee</TableCell>
              <TableCell>{data.monthly_fee}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Fine</TableCell>
              <TableCell>{data.fine_fee}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Arrears</TableCell>
              <TableCell>{data.arrears}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Balance</TableCell>
              <TableCell>{data.balance}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total Fee</TableCell>
              <TableCell>{data.total_fee}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Collection</TableCell>
              <TableCell>{data.collection}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Month/Year</TableCell>
              <TableCell>{data.fmonth} / {data.fyear}</TableCell>
            </TableRow>
          </Table>
        </View>

        <View style={styles.view}>
        <Text style={styles.header}>Your School Name</Text>
        <Text style={styles.text}>Address, contact no of your school</Text>
          
          <Text style={styles.text}>Student Copy</Text>
          <Table>
            <TableRow>
              <TableCell>Id: {data.idf}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Adm No:{data.fee_adm_no}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Name:{data.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Standard:{data.FeeStandard}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Monthly Fee</TableCell>
              <TableCell>{data.monthly_fee}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Fine</TableCell>
              <TableCell>{data.fine_fee}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Arrears</TableCell>
              <TableCell>{data.arrears}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Balance</TableCell>
              <TableCell>{data.balance}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total Fee</TableCell>
              <TableCell>{data.total_fee}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Collection</TableCell>
              <TableCell>{data.collection}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Month/Year</TableCell>
              <TableCell>{data.fmonth} / {data.fyear}</TableCell>
            </TableRow>
          </Table>
        </View>
      </Page>
    </Document>
  );
};

export default FeePdf;
