import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import userContext from "../context/UserContext";
import { useNavigate } from "react-router-dom";

export default function AssignTasks() {
  axios.defaults.withCredentials = true;

  const navigate = useNavigate();
  const [taskData, setTaskData] = useState([]);
  const [standards, setStandards] = useState([]);
  const [createdAtOptions, setCreatedAtOptions] = useState([]);
  const [selectedStandard, setSelectedStandard] = useState("");
  const [selectedCreatedAt, setSelectedCreatedAt] = useState("");
  const [error, setError] = useState(null);

  const { token } = useContext(userContext);
 
  useEffect(() => {
    const fetchStandards = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/classes`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setStandards(response.data || []); // Assuming response.data contains an array of distinct standards
      } catch (error) {
        console.error("Error fetching task standards:", error.response ? error.response.data : error.message);
        setError('Error fetching task standards');
      }
    };
    
    const fetchCreatedAtOptions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/tasks/created-at-options`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCreatedAtOptions(response.data.data || []);
      } catch (error) {
        console.error("Error fetching created at options:", error.response ? error.response.data : error.message);
        setError('Error fetching created at options');
      }
    };

    fetchStandards();
    fetchCreatedAtOptions();
  }, [token]);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/tasks`, {
          params: {
            task_standard: selectedStandard,
            created_at: selectedCreatedAt,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTaskData(response.data.data || []);
      } catch (error) {
        console.error("Error fetching tasks:", error.response ? error.response.data : error.message);
        setError('Error fetching tasks');
      }
    };
    
    fetchTasks();
  }, [selectedStandard, selectedCreatedAt, token]);

  const handleStandardChange = (e) => {
    setSelectedStandard(e.target.value);
  };

  const handleCreatedAtChange = (e) => {
    setSelectedCreatedAt(e.target.value);
  };

  const handleDeleteTask = async (syllabus_id) => {
    if (window.confirm("Are you sure you want to delete this task?")) {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/tasks/${syllabus_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        // Update taskData to remove the deleted task
        setTaskData(prevTasks => prevTasks.filter(task => task.syllabus_id !== syllabus_id));
        alert(response.data.message); // Show success message
      } catch (error) {
        console.error("Error deleting task:", error.response ? error.response.data : error.message);
        alert('Error deleting task');
      }
    }
  };

  return (
    <div className="container">
      {error && <div className="alert alert-danger">{error}</div>}
      <h1 className='text-center'>Assign Tasks</h1>
      <div className="d-flex justify-content-end">
        <button className="btn btn-primary" onClick={() => navigate('/dashboard/taskcreate')}>New Task</button>
      </div>
      <div className="row mb-3">

        <div className="col-md-6">
          <label ><b>Standard</b></label>
          <select 
            value={selectedStandard} 
            onChange={handleStandardChange} 
            className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
          >
            <option value="">Select Task Standard</option>
            {standards.map((standard) => (
              <option key={standard.standard} value={standard.standard}>
                {standard.standard}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6">
          <label className='text-center'><b>Select Date</b></label>
          <select 
            value={selectedCreatedAt} 
            onChange={handleCreatedAtChange} 
            className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
          >
            <option value="">Select Date</option>
            {createdAtOptions.map((createdAt, index) => (
              <option key={index} value={createdAt.created_at}>
                {createdAt.created_at}
              </option>
            ))}
          </select>
        </div>
      </div>
      <hr />   
      <table className="table table-bordered">
        <thead>
          <tr>
            <th style={{width:"5%"}}>ID</th>
            <th style={{width:"15%"}}>Date</th>
            <th style={{width:"10%"}}>Standard</th>
            <th style={{width:"10%"}}>Subject</th>
            <th style={{width:"45%"}}>Task</th>
            <th style={{width:"15%"}} className='text-center'>Action</th>
          </tr>
        </thead>
        <tbody>
  {taskData.length === 0 ? (
    <tr>
      <td colSpan="5">No tasks found</td>
    </tr>
  ) : (
    taskData.map((task) => (
      <tr key={task.syllabus_id}>
        <td>{task.syllabus_id}</td>
        <td>{task.created_at}</td>
        <td>{task.task_standard}</td>
        <td>{task.subject}</td>
        <td style={{color:"blue", fontStyle:"bold"}}>{task.task}</td>
        <td className='text-center'>
          <button className='btn btn-success'>Edit</button>
          <button 
            className='btn btn-danger ml-1' 
            onClick={() => handleDeleteTask(task.syllabus_id)}
          >
            Del
          </button>
        </td>
      </tr>
    ))
  )}
</tbody>
      </table>
    </div>
  );
}
