import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import userContext from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import { PDFDownloadLink } from '@react-pdf/renderer';
import FeePdf from './Feepdf'; // Import the PDF component

export default function FeeDetail() {
  axios.defaults.withCredentials = true;
  
  const navigate = useNavigate();
  const { token } = useContext(userContext);
  
  // State variables
  const [invdetails, setInvdetails] = useState([]);
  const [error, setError] = useState(null);
  const [standards, setStandards] = useState([]);
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [selectedStandard, setSelectedStandard] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/fee`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        if (Array.isArray(response.data.data)) {
          setInvdetails(response.data.data);
          setStandards([...new Set(response.data.data.map((inv) => inv.FeeStandard))]);
          setYears([...new Set(response.data.data.map((inv) => inv.fyear))]);
          setMonths([...new Set(response.data.data.map((inv) => inv.fmonth))]);
        } else {
          console.error("Expected an array of invoices, but got:", response.data.data);
        }
      } catch (error) {
        setError(error);
        console.error("Error fetching invoices:", error);
      }
    };
    
    fetchInvoices();
  }, [token]);

  const handleCollect = (idf) => {
    navigate(`/dashboard/feecollection/${idf}`);
  };

  const handleStandardChange = (e) => {
    setSelectedStandard(e.target.value);
    setCurrentPage(1);
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
    setCurrentPage(1);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    setCurrentPage(1);
  };

  const handlepaid = () => {
    navigate("/dashboard/feepaid");
  };

  const handleunpaid = () => {
    navigate("/dashboard/unpaidfee");
  };

  const filteredInvdetails = invdetails.filter((inv) => 
    (!selectedStandard || inv.FeeStandard === selectedStandard) &&
    (!selectedYear || inv.fyear === selectedYear) &&
    (!selectedMonth || inv.fmonth === selectedMonth)
  );

  const totalPages = Math.ceil(filteredInvdetails.length / recordsPerPage);
  const startIndex = (currentPage - 1) * recordsPerPage;
  const currentInvDetails = filteredInvdetails.slice(startIndex, startIndex + recordsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDelete = async (idf) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/fee/${idf}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setInvdetails(invdetails.filter((inv) => inv.idf !== idf));
        alert("Record deleted successfully.");
      } catch (error) {
        console.error("Error deleting record:", error);
      }
    }
  };

  const handleInsertFees = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/fee/insert-fees`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert(`Inserted Records: ${response.data.insertedRecords}`);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error inserting fees:", error);
      alert("Failed to insert fees");
    }
  };

  return (
    <div className="container">
      {error && <div className="alert alert-danger">{error.toString()}</div>}
      <div className="card-body">
        <div className="col-md-12 text-center">
          <h1><b>FEE RECORD</b></h1>
        </div>

        <div className="row mb-3 align-items-center">
          <div className="col-md-4">
            <select
              value={selectedStandard}
              onChange={handleStandardChange}
              className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
            >
              <option value=""><b>Select Standard</b></option>
              {standards.map((standard) => (
                <option key={standard} value={standard}>
                  Class: {standard}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-4">
            <select
              value={selectedYear}
              onChange={handleYearChange}
              className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
            >
              <option value="">Select Year</option>
              {years.map((year) => (
                <option key={year} value={year}>
                  Year: {year}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-4">
            <select
              value={selectedMonth}
              onChange={handleMonthChange}
              className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
            >
              <option value="">Select Month</option>
              {months.map((month) => (
                <option key={month} value={month}>
                  Month No: {month}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div>
          <button className="btn btn-primary btn-sm mb-1 shadow p-3 rounded" onClick={handleInsertFees}>
            <b>Generate Invoices</b>
          </button>
          <button className="btn btn-success ml-1 btn-sm mb-1 shadow p-3 rounded" onClick={handlepaid}>
            <b>Fee Paid Detail</b>
          </button>
          <button className="btn btn-danger ml-1 btn-sm mb-1 shadow p-3 rounded" onClick={handleunpaid}>
            <b>Unpaid Fee Detail</b>
          </button>
          <button className="btn btn-warning ml-1 btn-sm mb-1 shadow p-3 rounded" onClick={() => navigate(`/dashboard/feemisc`)}>
            <b>Misc</b>
          </button>
        </div>

        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Id</th>
              <th>Adm No</th>
              <th>Standard</th>
              <th>Monthly Fee</th>
              <th>Fine</th>
              <th>Arrears</th>
              <th>Balance</th>
              <th>Total Fee</th>
              <th>Collection</th>
              <th>M/Y</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentInvDetails.length === 0 ? (
              <tr>
                <td colSpan="11" className="text-center">No invoices found</td>
              </tr>
            ) : (
              currentInvDetails.map((inv) => (
                <tr key={inv.idf}>
                  <td>{inv.idf}</td>
                  <td>{inv.fee_adm_no}</td>
                  <td>{inv.FeeStandard}</td>
                  <td>{inv.monthly_fee}</td>
                  <td>{inv.fine_fee}</td>
                  <td>{inv.arrears}</td>
                  <td>{inv.balance}</td>
                  <td>{inv.total_fee}</td>
                  <td>{inv.collection}</td>
                  <td>{inv.fmonth} / {inv.fyear}</td>
                  <td>
                    <button className="btn btn-success" onClick={() => handleCollect(inv.idf)}>Collect</button>
                    <button className="btn btn-danger" onClick={() => handleDelete(inv.idf)}>Delete</button>
                    
                    {invdetails.length > 0 && (
  <PDFDownloadLink 
    fileName="Invoice.pdf" 
    document={<FeePdf data={inv} />} 
    style={{ textDecoration: 'none', padding: '10px' }}
  >
    <button className="btn btn-info">Generate PDF</button>
  </PDFDownloadLink>
)}
                  
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        {/* Pagination Controls */}
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              className={`btn ${currentPage === index + 1 ? "btn-success" : "btn-primary"}`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
