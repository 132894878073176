import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import userContext from "../context/UserContext";

export default function StudentEdit() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [image, setImage] = useState(null); 
    const [student, setStudent] = useState({
        adm_no: "",
        name: "",
        standard: "",
        image: "",
        monthly_fee: "",
        status: "",
        father: "",
        adm_date: "",
        adm_standard: "",
        mobile: "",
        address: "",
        email: ""
    });
  
    const [standards, setStandards] = useState([]);
    const { token } = useContext(userContext);

    useEffect(() => {
        const fetchStudent = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/${id}`, { headers: { Authorization: `Bearer ${token}` } });
                console.log("Fetched student data:", response.data);
                setStudent(response.data);
            } catch (error) {
                console.error("Error fetching student:", error);
            }
        };

        const fetchStandards = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/classes`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                console.log(response.data); // Check what data you're receiving
                setStandards(response.data); // Set standards to state
            } catch (err) {
                console.error("Error fetching standards:", err);
            }
        };

        fetchStudent();
        fetchStandards();
    }, [id, token]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStudent((prevState) => ({ ...prevState, [name]: value }));
    };

   

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formattedAdmDate = new Date(student.adm_date).toISOString().split('T')[0]; // Formats date to YYYY-MM-DD
    
        const formData = new FormData();
    
        // Append existing fields
        formData.append('admno', student.adm_no);
        formData.append('name', student.name);
        formData.append('standard', student.standard);
        formData.append('monthly_fee', student.monthly_fee);
        formData.append('status', student.status);
        formData.append('father', student.father);
        formData.append('adm_date', formattedAdmDate);
        formData.append('adm_standard', student.adm_standard);
        formData.append('mobile', student.mobile);
        formData.append('address', student.address);
        formData.append('email', student.email);
    
        // Append new image if it exists
        if (image) {
            formData.append('image', image);
        }
    
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/students/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            });
    
            navigate('/dashboard/studentlist', { state: { message: 'Student updated successfully!' } });
        } catch (error) {
            console.error("Error updating student:", error);
        }
    };
    

  const handleback = () => {
    navigate('/dashboard/studentList');
}
  
    return (
        <div>
            <h1 className='text-center'>Edit Student</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="adm_no"><b>Admission Number</b></label>
                    <input type="text" className="form-control" id="adm_no" name="adm_no" value={student.adm_no} onChange={handleChange} disabled />
                </div>
                <div className="form-group">
                    <label htmlFor="name"><b>Name</b></label>
                    <input type="text" className="form-control" id="name" name="name" value={student.name} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="standard"><b>Standard</b></label>
                    <select
                        className="form-select"
                        id="standard"
                        name="standard"
                        value={student.standard}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select Standard...</option>
                        {standards.map((std) => (
                            <option key={std.sid} value={std.standard}>
                                {std.standard}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="monthly_fee"><b>Monthly Fee</b></label>
                    <input type="text" className="form-control" id="monthly_fee" name="monthly_fee" value={student.monthly_fee} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="status"><b>Status</b></label>
                    <input type="text" className="form-control" id="status" name="status" value={student.status} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="father"><b>Father's Name</b></label>
                    <input type="text" className="form-control" id="father" name="father" value={student.father} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="adm_date"><b>Admission Date</b></label>
                    <input type="date" className="form-control" id="adm_date" name="adm_date" value={student.adm_date} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="adm_standard"><b>Admission Standard</b></label>
                    <select
                        className="form-select"
                        id="adm_standard"
                        name="adm_standard"
                        value={student.adm_standard}
                        onChange={handleChange}
                    >
                        <option value="">Select Admission Standard...</option>
                        {standards.map((std) => (
                            <option key={std.sid} value={std.standard}>
                                {std.standard}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="mobile"><b>Mobile</b></label>
                    <input type="text" className="form-control" id="mobile" name="mobile" value={student.mobile} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="address"><b>Address</b></label>
                    <input type="text" className="form-control" id="address" name="address" value={student.address} onChange={handleChange} />
                </div>
                <div className="form-group">
                    <label htmlFor="email"><b>Email</b></label>
                    <input type="email" className="form-control" id="email" name="email" value={student.email} onChange={handleChange} />
                </div>
                
                <div className="form-group">
    <label htmlFor="image"><b>Update Image</b></label>
    <input
        type="file"
        className="form-control"
        id="image"
        name="image"
        onChange={(e) => setImage(e.target.files[0])} // Track selected image
    />
</div>


                <button type="submit" className="btn btn-primary">Update Student</button>
                <button type="button" className="btn btn-secondary ms-2" onClick={handleback}>Back</button>
            </form>
        </div>
    );
}